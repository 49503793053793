import { Link } from 'react-router-dom';
import logoFacilsure from '../../../../../assets/logo_facilsure.svg';
import logoAseguradora from '../../../../../assets/logo_helvetia.svg';
import styles from '../../../../../components/Layouts/Layout/layout.module.scss';
import customStyles from './navTopRight.module.scss';

interface NavTopRightProps {
  isValoracion: boolean;
  isExposicionMotivos: boolean;
  isSegurpedia: boolean;
  title: string | undefined;
  logout: () => void;
}

const NavTopRight: React.FC<NavTopRightProps> = ({
  isSegurpedia,
  isExposicionMotivos,
  title,
  isValoracion,
  logout,
}) => (
  <>
    {isExposicionMotivos || isSegurpedia ? (
      <>
        <h1 className={styles.exposicionMotivosTitle}>{title}</h1>
        <ul className={`${styles.headerMenu} ${styles.isExposicion}`}>
          <li
            className={`${styles.headerMenuItem} ${styles.isRight} ${customStyles.isPacc}`}
          >
            <Link className={styles.logoLink} to="/">
              <img
                className={styles.logoFacilsureImg}
                src={logoFacilsure}
                alt="Logotipo de Facilsure"
              />
            </Link>
          </li>
          <li className={`${styles.headerMenuItem} ${customStyles.isPacc}`}>
            <Link className={styles.logoLink} to="/">
              <img
                className={styles.logoFacilsureImg}
                src={logoAseguradora}
                alt="Logotipo helvetia"
              />
            </Link>
          </li>
        </ul>
      </>
    ) : (
      <ul className={styles.headerMenu}>
        <li
          className={`${styles.headerMenuItem} ${styles.isRight} ${customStyles.isPacc}`}
        >
          <Link className={styles.logoLink} to="/">
            <img
              className={styles.logoFacilsureImg}
              src={logoFacilsure}
              alt="Logotipo de Facilsure"
            />
          </Link>
        </li>
        <li className={`${styles.headerMenuItem} ${customStyles.isPacc}`}>
          <Link className={styles.logoLink} to="/">
            <img
              className={styles.logoFacilsureImg}
              src={logoAseguradora}
              alt="Logotipo helvetia"
            />
          </Link>
        </li>
      </ul>
    )}
  </>
);

export default NavTopRight;
