import styles from './loginLeftPanel.module.scss';
import logoFacilsure from '../../../../assets/logo_facilsure.png';
import logoHelvetia from '../../assets/logo_helvetia_white.svg';
const LoginLeftPanel: React.FC = () => (
  <div className={styles.titleWrapper}>
    <div className={styles.logosWrapper}>
      <img
        className={styles.logoFacilsure}
        src={logoFacilsure}
        alt="Facilsure"
      />
      <img className={styles.logoHelvetia} src={logoHelvetia} alt="Helvetia" />
    </div>
    <h1 className={styles.title}>
      Partner especialista en captación, formación y seguimiento de equipos de
      ventas de alto rendimiento.
    </h1>
  </div>
);

export default LoginLeftPanel;
