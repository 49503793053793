import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as IconLogout } from '../../../../../../assets/icon_logout.svg';
import styles from '../../../../../../components/Layouts/Layout/layout.module.scss';
import customStyles from './menuLogout.module.scss';
import { logout } from '../../../../../../store/auth/authSlice';
import { AppDispatch } from '../../../../../../store/store';

interface MenuLogoutProps {
  styleRoute: (route: string) => string;
  logout: () => void;
}

const MenuLogout: React.FC<MenuLogoutProps> = ({ styleRoute, logout }) => (
  <React.Fragment>
    <li className={`${styleRoute('/logout')}`}>
      <Link
        className={styles.menuLink}
        data-testid="logoutLink"
        to="/"
        onClick={logout}
      >
        <div className={`${styles.iconWrapper}`}>
          <IconLogout
            className={`${styles.menuIcon} ${customStyles.menuIcon}`}
          />
        </div>
        <span className={styles.menuText}>Desconectar</span>
      </Link>
    </li>
  </React.Fragment>
);

export default connect(null, (dispatch: AppDispatch) => ({
  logout: () => {
    dispatch(logout());
  },
}))(MenuLogout);
