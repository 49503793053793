import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as IconAdmin } from '../../../../../../assets/icon_admin.svg';
import styles from '../../../../../../components/Layouts/Layout/layout.module.scss';
import customStyles from './menuLogout.module.scss';

interface MenuLogoutProps {
  styleRoute: (route: string) => string;
}

const MenuLogout: React.FC<MenuLogoutProps> = ({ styleRoute }) => (
  <React.Fragment>
    <li className={`${styleRoute('/')}`}>
      <Link className={styles.menuLink} data-testid="administracionLink" to="/">
        <div className={`${styles.iconWrapper}`}>
          <IconAdmin
            className={`${styles.menuIcon} ${customStyles.menuIcon}`}
          />
        </div>
        <span className={styles.menuText}>Administracion</span>
      </Link>
    </li>
  </React.Fragment>
);
export default MenuLogout;
